export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Lexus-CA',
  hostName: 'https://analytics.lexusdds.ca',
  environmentName: 'production',
  appName: 'Lexus CA DDS Insight',
  applicationInsights: {
    instrumentationKey: '99c0c9e2-b2a0-4476-baed-20014c820c11'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#000', '#666666', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://lexusca-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://lexusca-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://lexusca-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/lexus-ca/favicon.ico',
  loginRedirectUrl: 'https://tcihub.okta.com/home/tcihub_ddslexusinsight_1/0oacrklqxhWetGxCc697/alncrl96u3eAdyyco697',
  logoutRedirectUrl: 'https://tcihub.okta.com/home/tcihub_ddslexusinsight_1/0oacrklqxhWetGxCc697/alncrl96u3eAdyyco697',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview', 'DigitalAdvertisingDashboard']
};
